<template>
    <ul class="menu-nav">
        <router-link to="/dashboard"
                     v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]">
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-architecture-and-city"></i>
                    <span class="menu-text">{{ $t("MENU.DASHBOARD") }}</span>
                </a>
            </li>
        </router-link>

        <li class="menu-section">
            <h4 class="menu-text">{{ $t("MENU.SYSTEM_SETTINGS") }}</h4>
            <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link :to="{name: 'items_index'}"
                     v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
        ]">
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-grid-menu"></i>
                    <span class="menu-text">{{ $t("MENU.ITEMS") }}</span>
                </a>
            </li>
        </router-link>
    </ul>
</template>

<script>
export default {
  name: "WebShopMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
